import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Container from '../components/container';
import {responsiveTitle1} from '../components/typography.module.css';

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Container>
      <h1 className={responsiveTitle1}>NOT FOUND</h1>
      <p>
        This page doesn't exist! Click <a href="/">here</a> to return home.
      </p>
    </Container>
  </Layout>
);

export default NotFoundPage;
